import React from "react";

import './footer.styles.scss';

const Footer = ({ hidden }) => {
  return <>
    <div className="footer">
      
    </div>
  </>
};

export default Footer