import { Route, Redirect } from 'react-router-dom';
import {auth} from '../../firebase/firebase.utils.js';
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';

export const PrivateRoute = ({ children, ...rest }) => {
	const authenticated = useSelector(state => auth)
	console.log(authenticated);
	return (
	  <Route
		{...rest}
		render={({ location }) =>
		  isLoaded(authenticated) && !isEmpty(authenticated) ? (
			children
		  ) : (
			<Redirect
			  to={{
				pathname: "/login",
				state: { from: location }
			  }}
			/>
		  )
		}
	  />
	);
  }