import React from "react";

//import Directory from '../../components/directory/directory.component'

import "./homepage.styles.scss";
//import { Mailchimp } from "../../components/mailchimp/mailchimp.component"
const HomePage = () => (
  <div className="homepage">
    <div className="banner">
      <span>First Time Shoppers Enjoy %25 Off <wbr /><a href="https://onceuponanothertime.biz/sassy-chic-unique/">Click Here To Shop With Us</a></span>
    </div>
    <div className="content">
      <h1>Donatations and Shopping for a Cause</h1>
      <p>Once Upon Another Time and our First Impressions Initiative is a budding non-profit 
        donation based online women’s fashion and apparel retail store.  Our cause benefits 
        underprivileged women entering into or back into the work force.
      </p>
      <p>
      Every time you buy from our store you help supply those in need with what they need to succeed! 
      </p>
      <h2>Donatations and Shopping for a Cause</h2>
      <div className="donations">
        <div className="donations-content">
          <p>Women’s Proffesional and Casual Clothing</p>
          <ul className="items">
            <li>Women’s Shoes</li>
            <li>Sewing Kits</li>
            <li>Curling and Flat Irons</li>
            <li>Handbags</li>
            <li>Irons and Ironing Boards</li>
            <li>Blow Dryers</li>
            <li>Belts and Bras</li>
            <li>Perfumes</li>
            <li>Costume Jewelry</li>
          </ul>
        </div>
      </div>
      <p className="dark">
        <strong>We pick-up door side donations  7 days a week.</strong><br />
        If you would like to donate to our cause and you are in the Raleigh area call or text: <strong>757-288-8979 to arrange a pickup.</strong>
      </p>
    </div>
  </div>
);

export default HomePage;
